import React from "react";
import { ExpandableTileCasesItem } from "../ExpandableTileCases";
import PictureProvider from "../../../components/Picture";

type BottomItemMobileProps = {
  bottomInfo: ExpandableTileCasesItem | null;
  closeBottomInfo: () => void;
};

const BottomItemMobile: React.FunctionComponent<BottomItemMobileProps> = ({
  closeBottomInfo,
  bottomInfo,
}): React.ReactElement | null => {
  return (
    bottomInfo && (
      <div className="expandableTile__bottomItem">
        <div
          onClick={closeBottomInfo}
          className="expandableTile__bottomItem__closeBtn"
        >
          <span>x</span>
        </div>
        <p
          className={`expandableTile__bottomItem__header ${
            bottomInfo.pictureDown
              ? "expandableTile__bottomItem__header--smaller"
              : ""
          }`}
        >
          {bottomInfo.headerDown}
        </p>
        <div
          className={`expandableTile__bottomItem__info ${
            bottomInfo.pictureDown
              ? "expandableTile__bottomItem__info--smaller"
              : ""
          }`}
          dangerouslySetInnerHTML={{ __html: bottomInfo.contentDown }}
        />
        {bottomInfo.pictureDown && (
          <PictureProvider
            data={bottomInfo.pictureDown}
            className="expandableTile__bottomItem__img"
          />
        )}
      </div>
    )
  );
};

export default BottomItemMobile;
