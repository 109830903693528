import React, {
  FunctionComponent,
  ReactElement,
  useRef,
  useState,
} from "react";
import { ExpandableTileCasesItem } from "../ExpandableTileCases";
import BottomItemDesktop from "./BottomItemDesktop";
import Card from "./Card";

type DesktopCardsProps = {
  items: Array<ExpandableTileCasesItem>;
};

const DesktopCards: FunctionComponent<DesktopCardsProps> = ({
  items,
}): ReactElement => {
  const [activeIndex, setActiveIndex] = useState<number | null>(null);
  const cardsRef = useRef<HTMLDivElement>(null);
  const bottomInfoRef = useRef<HTMLDivElement>(null);
  const handleActivateCard = (index: number): void => {
    setActiveIndex(index);
    cardsRef.current?.scrollIntoView();
  };

  const closeBottomInfo = (): void => {
    setActiveIndex(null);
    cardsRef.current?.scrollIntoView();
  };

  const activeItem = activeIndex !== null ? items[activeIndex] : null;

  return (
    <>
      <div className="expandableTile__cardsDesktop" ref={cardsRef}>
        {items.map((item, index) => (
          <Card
            key={index}
            item={item}
            isActive={index === activeIndex}
            handleActivateCard={() => handleActivateCard(index)}
          />
        ))}
      </div>

      <div ref={bottomInfoRef}>
        <BottomItemDesktop
          closeBottomInfo={closeBottomInfo}
          bottomInfo={activeItem}
        />
      </div>
    </>
  );
};

export default DesktopCards;
