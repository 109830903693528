import React from "react";
import { PictureData } from "../../../types/PictureData";
import { IDynamicBlockComponent } from "../../dynamicPageBuilder/IDynamicBlockComponent";
import ExpandableTileCasesMobile from "./components/ExpandableTileCasesMobile";
import ExpandableTileCasesDesktop from "./components/ExpandableTileCasesDesktop";
import Media from "react-media";

export type ExpandableTileCasesItem = {
  id: number;
  content: string;
  contentDown: string;
  header: string;
  headerDown: string;
  picture: PictureData;
  pictureDown: PictureData;
};

type ExpandableTileCasesProps = {
  background: string;
  data: Array<ExpandableTileCasesItem>;
};

const ExpandableTileCases: React.FunctionComponent<
  IDynamicBlockComponent<ExpandableTileCasesProps>
> = ({ blockData }): React.ReactElement => {
  const { data: items, background } = blockData as ExpandableTileCasesProps;

  const containerClass = `expandableTile__container desktop-container paddingsForBlocks ${
    background === "grey" ? "mainBackgroundGrey" : ""
  } `;

  return (
    <div className={containerClass}>
      <Media query="(max-width: 767px)" defaultMatches>
        {(matches) =>
          matches ? (
            <ExpandableTileCasesMobile items={items} />
          ) : (
            <ExpandableTileCasesDesktop items={items} />
          )
        }
      </Media>
    </div>
  );
};

export default ExpandableTileCases;
