import React, { useState } from "react";
import { ExpandableTileCasesItem } from "../ExpandableTileCases";
import MobileItem from "./MobileItem";

type ExpandableTileCasesMobileProps = {
  items: Array<ExpandableTileCasesItem>;
};

const ExpandableTileCasesMobile: React.FunctionComponent<
  ExpandableTileCasesMobileProps
> = ({ items }): React.ReactElement => {
  const [activeIndex, setActiveIndex] = useState<number | null>(null);
  const handleActivateCard = (index: number): void => setActiveIndex(index);
  const closeBottomInfo = (): void => setActiveIndex(null);
  const activeItem = activeIndex !== null ? items[activeIndex] : null;

  return (
    <>
      {items.map((item, index) => (
        <MobileItem
          key={index}
          item={item}
          activeItem={activeItem}
          closeBottomInfo={closeBottomInfo}
          handleActivateCard={() => handleActivateCard(index)}
        />
      ))}
    </>
  );
};

export default ExpandableTileCasesMobile;
