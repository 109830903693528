import React from "react";
import { ExpandableTileCasesItem } from "../ExpandableTileCases";
import PictureProvider from "../../../components/Picture";
import Icon from "../../../components/Icon";
type CardProps = {
  item: ExpandableTileCasesItem;
  isActive: boolean;
  handleActivateCard: () => void;
};

const Card: React.FunctionComponent<CardProps> = ({
  handleActivateCard,
  item,
  isActive,
}) => {
  return (
    <div
      className="expandableTile__card expandableTile__card--active"
      onClick={handleActivateCard}
    >
      <div>
        {item.picture && <PictureProvider data={item.picture} />}
        <h3
          className={`expandableTile__card__header ${
            !item.picture || !item.picture.publicUrl
              ? "expandableTile__card__header--biggerMargin"
              : ""
          }`}
        >
          {item.header}
        </h3>
        <div
          className="expandableTile__card__text"
          dangerouslySetInnerHTML={{ __html: item.content }}
        />
      </div>
      <div className="expandableTile__cardIcon" role="button">
        <Icon
          icon={isActive ? "UP_ARROW" : "DOWN_ARROW"}
          color={isActive ? "icon-orange" : "dark-grey"}
          width="30"
        />
      </div>

      {isActive && <div className="expandableTile__card__triangle" />}
    </div>
  );
};

export default Card;
