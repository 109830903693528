import React from "react";
import { closeIcon } from "../../../../utils/icons";
import { ExpandableTileCasesItem } from "../ExpandableTileCases";
import PictureProvider from "../../../components/Picture";

type BottomItemDesktopProps = {
  bottomInfo: ExpandableTileCasesItem | null;
  closeBottomInfo: () => void;
};

const BottomItemDesktop: React.FunctionComponent<BottomItemDesktopProps> = ({
  bottomInfo,
  closeBottomInfo,
}): React.ReactElement => {
  return (
    <section className="downContent">
      {bottomInfo && (
        <div className="section2">
          <div className="expandableTile__bottomItem desktop-container">
            <div
              onClick={closeBottomInfo}
              className="expandableTile__bottomItem__closeBtn"
            >
              {closeIcon}
            </div>
            <p className="expandableTile__bottomItem__header textContent-lg">
              {bottomInfo.headerDown}
            </p>
            <div
              className="expandableTile__bottomItem__info"
              dangerouslySetInnerHTML={{ __html: bottomInfo.contentDown }}
            />
            {bottomInfo.pictureDown && (
              <PictureProvider
                data={bottomInfo.pictureDown}
                className="expandableTile__bottomItem__img"
              />
            )}
          </div>
        </div>
      )}
    </section>
  );
};

export default BottomItemDesktop;
