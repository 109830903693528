import React, { useRef } from "react";
import Card from "./Card";
import BottomItemMobile from "./BottomItemMobile";
import { ExpandableTileCasesItem } from "../ExpandableTileCases";

type MobileItemProps = {
  item: ExpandableTileCasesItem;
  activeItem: ExpandableTileCasesItem | null;
  closeBottomInfo: () => void;
  handleActivateCard: () => void;
};

const MobileItem: React.FunctionComponent<MobileItemProps> = ({
  item,
  activeItem,
  handleActivateCard,
  closeBottomInfo,
}): React.ReactElement => {
  const cardRef = useRef<HTMLDivElement>(null);
  const bottomInfoRef = useRef<HTMLDivElement>(null);
  const isActive = activeItem?.content === item.content;
  const handleActiveCard = (): void => {
    handleActivateCard();
    setTimeout(
      () => bottomInfoRef.current?.scrollIntoView({ block: "center" }),
      200
    );
  };

  const handleCloseBottomInfo = (): void => {
    closeBottomInfo();
    setTimeout(() => cardRef.current?.scrollIntoView(), 200);
  };

  return (
    <div className="expandableTile__cardWrapper" ref={cardRef}>
      <Card
        item={item}
        isActive={isActive}
        handleActivateCard={handleActiveCard}
      />
      <div ref={bottomInfoRef}>
        {isActive && (
          <BottomItemMobile
            closeBottomInfo={handleCloseBottomInfo}
            bottomInfo={activeItem}
          />
        )}
      </div>
    </div>
  );
};

export default MobileItem;
