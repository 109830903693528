import React from "react";
import { ExpandableTileCasesItem } from "../ExpandableTileCases";
import { chunk } from "lodash";
import DesktopCards from "./DesktopCards";

const LINE_BREAK = 3;

type ExpandableTileCasesDesktopProps = {
  items: Array<ExpandableTileCasesItem>;
};

const ExpandableTileCasesDesktop: React.FunctionComponent<
  ExpandableTileCasesDesktopProps
> = ({ items }): React.ReactElement => {
  const itemsGroups = chunk(items, LINE_BREAK);

  return (
    <>
      {itemsGroups.map((group, index) => (
        <DesktopCards key={index} items={group} />
      ))}
    </>
  );
};

export default ExpandableTileCasesDesktop;
